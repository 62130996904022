import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { useState } from 'react'
import * as yup from 'yup'
import { AuthError, login as loginApi } from '@netpurpose/api'
import {
  AuthFormError,
  Button,
  Input,
  PasswordInput,
  SignInWithGoogle,
  Spacer,
} from '@netpurpose/np-ui'
import { AuthPage } from '../AuthPage'
import {
  Centered,
  FormSectionContainer,
  InputLabel,
  LoginActionsContainer,
  StyledLink,
} from './Login.style'

type FormFields = {
  username: string
  password: string
}

const initialValues: FormFields = {
  username: '',
  password: '',
}

const LoginForm = () => {
  const [loading, setLoading] = useState(false)
  const [authError, setAuthError] = useState<AuthError | undefined>(undefined)

  const login = async (username: string, password: string) => {
    try {
      setLoading(true)
      await loginApi(username, password)
      setLoading(false)
    } catch (error) {
      setAuthError(error as AuthError)
      setLoading(false)
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object({
          username: yup.string().email('Must be a valid email').required('Required'),
          password: yup.string().required('Required'),
        })}
        onSubmit={({ username, password }) => {
          login(username, password)
        }}
      >
        <Form>
          <FormSectionContainer>
            <InputLabel htmlFor="username">Email</InputLabel>
            <Form.Item name="username">
              <Input
                id="username"
                name="username"
                placeholder="Enter email address"
                suffix={<span />}
              />
            </Form.Item>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Form.Item name="password">
              <PasswordInput
                id="password"
                name="password"
                placeholder="Enter password"
                suffix={<span />}
              />
            </Form.Item>
            <LoginActionsContainer>
              <Form.Item name="submit" wrapperCol={{ offset: 0 }}>
                <Button
                  level="tertiarySubmit"
                  disabled={false}
                  block
                  size="large"
                  loading={loading}
                >
                  Sign in
                </Button>
              </Form.Item>
              <AuthFormError {...(authError ? { error: authError } : {})} />
              <Centered>
                <StyledLink href="/forgot-password">Forgot password?</StyledLink>
              </Centered>
            </LoginActionsContainer>
          </FormSectionContainer>
        </Form>
      </Formik>
      <Spacer orientation="horizontal" spacing={2} />
      <SignInWithGoogle />
    </>
  )
}

export const Login = () => (
  <AuthPage>
    <LoginForm />
  </AuthPage>
)
