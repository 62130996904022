import styled from 'styled-components'
import { NextLink as Link } from '@netpurpose/np-ui'

export const LoginActionsContainer = styled.div`
  margin-top: 0.5rem;

  > * {
    margin-bottom: 1rem;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`

export const InputLabel = styled.label`
  && {
    font-size: 0.875rem;
    line-height: 1rem;
    color: ${({ theme }) => theme.colors.architecture6};
    margin-bottom: 0.5rem;
  }
`

export const FormSectionContainer = styled.div<{ $center?: boolean }>`
  display: flex;
  flex-direction: column;
`

export const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledLink = styled(Link)<{ $black?: boolean }>`
  font-weight: bold;
  color: ${({ theme, $black }) => ($black ? theme.colors.typePrimary : theme.colors.actionLink)};
  font-size: 0.875rem;
  line-height: 1rem;
`
